/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.nav-container {
  display: flex;
  padding: 20px 30px 20px 30px;
  justify-content: space-between;
  align-items: center;
  background-color: #ffe5c9;
}
.space {
  height: 75px;;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-button {
  background-color: #ffe5c9;
  border-radius: 8px;
  box-sizing: border-box;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  transition: color 100ms;
  vertical-align: baseline;
  touch-action: manipulation;
  transition: 0.3s;       
}
.nav-button:hover,
.nav-button:focus {
  background-color: #ffd2a3;
}

.nav-header {
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
  letter-spacing: 3.5px;
  font-weight: 900;
  color: #000000;
  text-decoration: none;
}
.nav-header:hover,
.nav-header:focus {
  color: #575757;
}

.margin-div {
  margin-left: 5%;
  margin-right: 5%;
}

.title-container {
  display: flex;
  flex-direction: column;
  padding: 10px 0% 50px 0%;
}
.title {
  font-family: 'Montserrat', sans-serif;
  font-size: 42px;
  letter-spacing: 3px;
  font-weight: 600;
}
.subtitle {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #393939
}

.projects-container {
  display: flex;
  flex-direction: row;
  margin-left: 3%;
  margin-right: 3%;
  flex-wrap: wrap; /* Allow items to wrap to the next row when needed */
  justify-content: center;
  row-gap: 30px;
  column-gap: 3%;
}
.proj-sub-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}
/* @media (max-width: 490px){
.proj-sub-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: flex-start;
  gap: 30px;
  min-height: 150px;
  padding-right: 20px;
  border: solid #ffe5c9 2px;
}} */
.mini-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  letter-spacing: 3px;
  font-weight: 500;
  text-decoration: none;
  color: #000000;
  margin-top: 10px;
}
.mini-title:hover {
  text-decoration: none;
}
.mini-title-proj {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  letter-spacing: 2px;
  font-weight: 500;
  text-decoration: none;
  color: #000000;
  margin-top: 5px;
}
.mini-title-proj:hover {
  text-decoration: none;
}
.mini-subtitle {
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 400;
  color: #393939;
  text-decoration: none;
  margin-top: 20px;
}
.mini-subtitle:hover {
  text-decoration: none;
}
.mini-subtitle-proj {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #393939;
  text-decoration: none;
  margin-top: 10px;
}
.mini-subtitle-proj:hover {
  text-decoration: none;
}
.proj-preview-img {
  max-width: none;
  max-height: none;
  width: 100%; /* Make the image's width match the width of the div */
  height: auto; /* Allow the image's height to adjust proportionally */
  position: absolute; /* Position the image absolutely within the container */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.proj-preview-img-div {
  width: 100%; /* Set the width of the rectangular div */
  height: 150px; /* Set the height of the rectangular div */
  border: solid #ffe5c9 2px;
  overflow: hidden; /* Hide any overflowing parts of the image */
  position: relative;
}

.section-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.left {
  display: flex;
  width: 25%;
  flex: 1;
}
.right {
  display: flex;
  width: 75%;
}
.section-gallery {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-top: 60px;
}
.section-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 600;
}
.section-body {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}
.equation-img {
  width:150px
}
.wide-img {
  margin-left: 0%;
  margin-right: 0%;
  width: 100%;
  padding-top: 80px;
}

.mid-page-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 600;
  margin-top: 60px;
}

.footer-container {
  display: flex;
  padding: 20px 30px 20px 30px;
  margin-top: 150px;
  justify-content: space-between;
  align-items: center;
  background-color: #ffe5c9;
}
.footer-button {
  background-color: #ffe5c9;
  border-radius: 8px;
  box-sizing: border-box;
  color: black;
  cursor: pointer;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 500;
  height: 40px;
  line-height: 20px;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  transition: color 100ms;
  vertical-align: baseline;
  touch-action: manipulation;
  transition: 0.3s;       
}
.footer-button:hover,
.footer-button:focus {
  background-color: #ffd2a3;
}

.gallery-img {
  height: 400px;
}
.gallery-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 60px;
}
.github-link {
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  font-weight: 400;
  text-decoration: none;
  color: #004cff;
  width: fit-content;
}
.github-link:hover {
  text-decoration: none;
  font-weight: 600;
  width: fit-content;
}
.gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.column {
  display: flex;
  flex-direction: column;
}
.proj-text {
  display: flex;
  flex-direction: column;
  /* border: solid #ffe5c9 2px; */
  flex-grow: 1;
  /* background-color: rgb(255, 244, 231); */
}
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.mid-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 32px;
  letter-spacing: 3px;
  font-weight: 700;
  color: #A44200;
  margin-top: 50px;
  margin-bottom: 10px;
}
.mid-subtitle {
  font-family: 'Montserrat', sans-serif;
  font-size: 26px;
  font-weight: 600;
  color: #A44200;
  margin-top: 20px;
  margin-bottom: 10px;
}
.proj-detail-box {
  display: flex;
  flex-direction: column;
}
.proj-img {
  max-width: 500px;
}
.dist-graph {
  max-width: 400px;
}
.table-img {
  max-width: 800px;
}
.large-img {
  max-width: 800px;
}
.proj-link {
  text-decoration: none; /* Remove underlines or other decorations */
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  min-height: 150px;
  width: 31%;
}

.table {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.table-column {
  display: flex;
  flex-direction: column;
}
.table-header{
  display: flex;
  justify-content: center;
  padding: 5px 5px 5px 5px;
  border: 1px solid #000000;
  font-weight: bold;
}
.table-box{
  display: flex;
  border: 1px solid #000000;
  justify-content: center;
  padding: 5px 5px 5px 5px;
}
.tab-img{
  max-width: 600px;
}

.about-background {
  /*background-image: url("./assets/images/beach-unsplash.jpeg");*/
  background-position: center top;
  background-size: 100% auto;
}
.round-img {
  border-radius: 150px;
  max-width: 300px;
  margin-bottom: 50px;
}
.center {
  display: flex;
  justify-content: center;
}
.margin-cont {
  width: fit-content;
}
.home-title {
  font-family: 'Montserrat', sans-serif;
  font-size: 28px;
  letter-spacing: 3px;
  font-weight: 600;
  color: #000000;
  margin-top: 50px;
  margin-bottom: 10px;
}
hr.basic {
  border-top: 2px solid #ffd2a3;
  margin-bottom: 40px;
}

/* CSS */
.button-54 {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: fit-content;
}
.button-54:active {
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}
@media (min-width: 768px) {
  .button-54 {
    padding: 0.25em 0.75em;
  }
}

.blurb-container {
  display: flex;
  flex-direction: column;
  padding: 0px 0% 0px 0%;
}
.blurb {
  font-family: 'Montserrat', sans-serif;
  font-size: 32px;
  letter-spacing: 1.5px;
  font-weight: 500;
  color: #A44200;
  margin-top: 10px;
  margin-bottom: 10px;
}
.skills-box{
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}
.skill-button {
  background-color: #ffe5c9;
  border-radius: 8px;
  box-sizing: border-box;
  color: #000000;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 500;
  min-height: 40px;
  line-height: 20px;
  padding: 10px 16px;
  position: relative;
  text-align: center;
  transition: color 100ms;
  vertical-align: baseline;
  touch-action: manipulation;
  transition: 0.3s;       
}
img.logo {
  height: 30px;
}
.rowgap {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: start;
  align-items: center;
}